@media (max-width: 3000px) {
  .ecommerce-sale-prospect-select {
    width: 71% !important;
  }
}
@media (max-width: 2000px) {
  .ecommerce-sale-prospect-select {
    width: 71.5% !important;
  }
}

@media (max-width: 1700px) {
  .ecommerce-sale-prospect-select {
    width: 72% !important;
  }
}

@media (max-width: 1500px) {
  .ecommerce-sale-prospect-select {
    width: 70% !important;
  }
}

@media (max-width: 1450px) {
  .ecommerce-sale-prospect-select {
    width: 74.5% !important;
  }
}

@media (max-width: 1430px) {
  .ecommerce-sale-prospect-select {
    width: 76% !important;
  }
}

@media (max-width: 1410px) {
  .ecommerce-sale-prospect-select {
    width: 77% !important;
  }
}

@media (max-width: 1395px) {
  .ecommerce-sale-prospect-select {
    width: 77% !important;
  }
}

@media (max-width: 1360px) {
  .ecommerce-sale-prospect-select {
    width: 80% !important;
  }
}

@media (max-width: 1322px) {
  .ecommerce-sale-prospect-select {
    width: 82% !important;
  }
}

@media (max-width: 1305px) {
  .ecommerce-sale-prospect-select {
    width: 82% !important;
  }
}

@media (max-width: 1280px) {
  .ecommerce-sale-prospect-select {
    width: 82% !important;
  }
}
@media (max-width: 1168px) {
  .ecommerce-sale-prospecto-person-type-container {
    margin-right: 20% !important;
  }
  .card-content-steps-body-content {
    width: 76%;
  }
  .ecommerce-sale-prospect-select {
    width: 85% !important;
  }
}

@media (max-width: 960px) {
  .ecommerce-sale-prospecto-person-type-radio-container {
    display: inline-block;
    align-items: center;
    flex-direction: row;
  }
  .ecommerce-sale-form-prospect-container {
    display: block !important;
    height: 1400px !important;
  }
  .ecommerce-sale-form-prospect {
    display: block !important;
  }
  .ecommerce-sale-prospecto-person-type-container {
    display: block !important;
    margin-left: 10% !important;
  }
  .ecommerce-sale-prospecto-person-type-title {
    color: #515151 !important;
  }
  .ecommerce-sale-prospecto-person-type-label {
    color: #515151 !important;
  }
  .ecommerce-sale-prospecto-person-type-input-radio[type="radio"]::after {
    border: 3px solid #db0d15 !important;
  }
  .card-content-steps-body-content {
    width: 100% !important;
    display: block;
    margin-left: 3%;
  }
  .ecommerce-sale-prospecto-form {
    display: block !important;
    width: 100%;
  }
  .ecommerce-sale-prospecto-input-container {
    display: block;
    flex-direction: column;
    align-self: center;
  }
  .ecommerce-sale-form-prospect-buttons {
    flex-direction: column-reverse !important;
    justify-content: center;
    align-items: center;
  }
  .ecommerce-sale-form-prospect-buttons button {
    margin-bottom: 10px;
  }
  /* .continueButton {
    margin-right: 6%;
  } */
  /* .ecommerce-sale-form-prospect-buttons-continue button {
    margin-left: 0pt;
  } */
  .ecommerce-sale-form-prospect-buttons-back button {
    margin-right: 0pt !important;
  }
  .ecommerce-sale-prospect-select {
    width: 100% !important;
  }
}

/* @media (max-width: 715px){
  .continueButton {
    margin-right: 0pt;
  }
} */
.ecommerce-sale-form-prospect-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: linear-gradient(
    270deg,
    #ba282b 4.1%,
    #e8474d 52.73%,
    #ba282b 97.46%
  );
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.ecommerce-sale-form-prospect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 6%;
}
.ecommerce-sale-prospecto-form-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-bottom: 5%;
}

.ecommerce-sale-prospecto-form {
  height: 100%;
  width: 100%;
  padding-top: 25px;
  padding-left: 25px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  justify-content: center;
  align-items: center;
}

.type-payments-prospecto-identification-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='62' height='62' viewBox='0 0 62 62' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.4167 5.16675L47.2698 11.0567C47.7956 11.2209 48.2552 11.5488 48.5815 11.9927C48.9077 12.4365 49.0836 12.973 49.0833 13.5238V18.0834H54.25C54.9351 18.0834 55.5922 18.3556 56.0767 18.8401C56.5612 19.3245 56.8333 19.9816 56.8333 20.6667V25.8334H23.25V20.6667C23.25 19.9816 23.5222 19.3245 24.0066 18.8401C24.4911 18.3556 25.1482 18.0834 25.8333 18.0834H43.9167V15.4226L28.4167 10.5762L12.9167 15.4226V34.5496C12.9163 36.1312 13.279 37.6917 13.9768 39.1111C14.6746 40.5304 15.6889 41.7706 16.9415 42.7362L17.4298 43.0875L28.4167 50.5817L38.1868 43.9168H25.8333C25.1482 43.9168 24.4911 43.6446 24.0066 43.1601C23.5222 42.6756 23.25 42.0186 23.25 41.3334V31.0001H56.8333V41.3334C56.8333 42.0186 56.5612 42.6756 56.0767 43.1601C55.5922 43.6446 54.9351 43.9168 54.25 43.9168L45.9317 43.9193C44.9319 45.2368 43.7177 46.3993 42.315 47.3552L28.4167 56.8334L14.5183 47.3578C12.4323 45.9355 10.7254 44.0252 9.54578 41.793C8.3662 39.5608 7.74974 37.0743 7.75 34.5496V13.5238C7.75031 12.9734 7.92641 12.4375 8.25262 11.9942C8.57883 11.5509 9.03811 11.2234 9.5635 11.0593L28.4167 5.16675Z' fill='white'/%3E%3C/svg%3E%0A");
  position: absolute;
  content: "";
  background-size: contain;
  width: 83px;
  height: 83px;
  background-repeat: no-repeat;
  margin-top: 15px;
  margin-right: -15%;
  top: 0;
  right: 0;
  z-index: 1;
}

.ecommerce-sale-prospecto-input-container label {
  color: #515151;
  font-family: "Poppins-Bold";
}

.ecommerce-sale-prospecto-input-container label sup {
  color: red;
}

.ecommerce-sale-prospecto-input-container input,
.ecommerce-sale-prospecto-input-container select,
.ecommerce-sale-prospecto-input-container .ecommerce-sale-prospect-input {
  height: 50px;
  width: 90%;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 2px 2px 4px rgba(204, 204, 204, 0.25);
  border-radius: 10px;
  padding: 10px;
  font-family: "Poppins-Bold", sans-serif;
}

.ecommerce-sale-prospecto-input-container input:focus,
focus .ecommerce-sale-prospecto-input-container select:focus {
  outline: 2px solid #8db0ef;
}

.ecommerce-sale-prospect-input {
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: space-between;
}

.ecommerce-sale-prospect-input-attach-icon {
  margin-right: 20px;
}

input {
  height: 45px;
}

input[type="file"] {
  height: 45px;
  display: none;
}

/* Remove Arrows/Spinners */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ecommerce-sale-prospect-input {
  height: 110px;
  background: #e7e7e7;
  outline: none;
  border: 1px solid #969696;
  cursor: pointer;
  padding-left: 10px;
  font-family: "Poppins-Bold", sans-serif;
  width: 100%;
  border-style: dashed;
  background-image: url("../../../../assets/images/arquivos.svg");
  background-repeat: no-repeat;
  background-position: center;
}

form {
  padding: 0px;
  top: 0px;
  height: 80%;
}

/* .button-form-position {
  display: flex;
  position: relative;
  margin-right: -30px;
  padding-bottom: auto;
} */

/* .inputError {
  background-color: rgba(236, 28, 36, 0.471) !important;
} */

/* .inputAccept {
  background-color: rgba(87, 162, 81, 0.471);
} */

.required,
.error {
  padding: 0px;
  margin: 0;
  margin-top: 3px;
  text-align: left;
  font-size: 12px;
  font-family: "Poppins-Regular", sans-serif;
  /* height: 0vh; */
}

.error {
  color: #db0d15;
}

.required {
  color: #000000;
}

.ecommerce-sale-prospecto-person-type-container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 1rem;
  margin-left: 5rem;
}

.ecommerce-sale-prospecto-person-type-title {
  margin-right: 1rem;
  font-family: "Poppins-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 17px;
  color: #515151;
  text-align: left;
}

.ecommerce-sale-prospecto-person-type-label {
  margin-left: 5px;
  font-family: "Poppins-Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 17px;

  color: #515151;
  cursor: pointer;
}
.ecommerce-sale-prospecto-person-type-label span {
  font-weight: lighter !important;
  font-size: small;
}

.ecommerce-sale-prospecto-person-type-input-radio[type="radio"] {
  width: 15px;
  height: 15px;
  margin-left: 1rem;
  margin-top: 0.8rem;
  border-radius: 50%;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
}

.ecommerce-sale-prospecto-person-type-input-radio[type="radio"]::after {
  width: 10px;
  height: 10px;
  display: flex;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  position: relative;
  content: "";
  visibility: visible;
  border: 3px solid #db0d15;
  background-color: #ffffff;
}

.ecommerce-sale-prospecto-person-type-input-radio[type="radio"]:checked::after {
  width: 10px;
  height: 10px;
  display: flex;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #db0d15;
  content: "";
  visibility: visible;
  border: 3px solid #db0d15;
}

.ecommerce-sale-prospecto-person-type-checkbox[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  background: #e7e7e7;
  border: 1px solid #969696;
  box-sizing: border-box;
  cursor: pointer;
}

.ecommerce-sale-prospecto-person-type-checkbox-requery-container {
  display: flex;
  justify-content: space-between;
}

.ecommerce-sale-prospecto-person-type-checkbox-container {
  display: flex;
}

/* @media (max-width: 1350px) {
  .ecommerce-sale-prospecto-form {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }
  .submit-button-container {
    grid-column-start: 2;
  }
}

@media (max-width: 960px) {
  .required,
  .error {
    color: #ffffff;

    height: 0vh;
  }
  .card-body-image-content,
  .card-content-steps-children-container {
    background: #c2050c;
  }

  .ecommerce-sale-prospecto-identification-icon {
    display: none;
  }

  .ecommerce-sale-prospecto-form-container {
    height: auto;
    padding: 0;
  }

  form {
    margin-top: 50px;
    margin-bottom: 0;
  }

  form > div {
    margin-bottom: 3vh;
  }

  input {
    width: 100vw;
  }

  form > div > input:not(:focus) {
    display: absolute;
    height: 50px;
  }

  form > div > input:focus {
    display: absolute;
    height: 50px;
  }

  form > div > input[type="file"] {
    height: 3vh;
  }

  .ecommerce-sale-prospecto-form {
    width: 100%;
    display: block;
    padding-top: 0px;
    margin-left: 0rem;
  }

  .ecommerce-sale-prospecto-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .ecommerce-sale-prospecto-input-container
    label:not(.ecommerce-sale-prospect-input) {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 0.8rem;
    color: #ffffff;
    margin-bottom: 9px;
  }

  .ecommerce-sale-prospect-input {
    align-items: center;
    display: flex;
  }

  form > div > select:not(:focus) {
    display: absolute;
    height: 50px;
  }

  .button-form-position {
    padding-right: 0%;
  }

  .ecommerce-sale-prospecto-identification-icon {
    width: 43px;
    height: 43px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    top: -120px;
    left: 0;
  }

  .error {
    color: #ffffff;
  }

  .ecommerce-sale-prospecto-person-type-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    margin-left: 0rem;
  }

  .ecommerce-sale-prospecto-person-type-title {
    color: #ffffff;
  }

  .ecommerce-sale-prospecto-person-type-label {
    margin-right: 2rem;
    color: #ffffff;
  }

  .ecommerce-sale-prospecto-person-type-input-radio[type="radio"]::after {
    border: 3px solid #ffffff;
    background-color: #ffffff;
  }

  .ecommerce-sale-prospecto-person-type-input-radio[type="radio"]:checked::after {
    background-color: #db0d15;
    border: 3px solid #ffffff;
  }
} */
