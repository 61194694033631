.modal-exception-container {
  width: 650px;
  height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-exception-content-container {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.modal-exception-text-bottom-container {
  display: block;
  flex-direction: column;
}

.modal-exception-text-top {
  text-align: left;
  font-family: "Poppins-Bold";
  font-style: normal;
  font-weight: 900;
  font-size: 19.2463px;
  line-height: 23px;

  color: #000000;
}

.modal-exception-text-bottom {
  font-family: "Poppins-Bold";
  font-size: 19.2463px;
  line-height: 23px;
  text-align: center;

  color: #000000;
}

.modal-exception-error-image {
  align-self: center;
  width: 182px;
  height: 152px;
}

.modal-exception-button:hover {
  background-color: #93040a;
}

.modal-exception-button {
  width: 75.4px;
  height: 36px;

  align-self: center;

  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 900;
  font-size: 13.5849px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;

  color: #ffffff;
  background: #db0d15;

  transition: all 0.3s ease-in-out;
}

.modal-exception-close-icon-button {
  width: auto;
  height: auto;
}

@media (max-width: 960px) {
  .modal-exception-container {
    width: 100%;
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .modal-exception-content-container {
    width: 95%;
  }
  .modal-exception-text-bottom {
    font-size: 16px;
  }
  .modal-exception-text-top {
    font-size: 16px;
  }
  .modal-exception-error-image {
    width: 132px;
    height: 90px;
  }
}
