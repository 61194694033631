.progress-bar-container {
  width: 100px;
  height: 475px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 40px;
  /* background-color: turquoise; */
}

.progress-bar-content-icons {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
}

.progress-bar-icon-container {
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1.03423px solid #ffffff;
  box-sizing: border-box;
}

.progress-bar-line-spaced {
  width: 1px;
  height: 40px;
  margin-top: 5px;
  align-self: center;
  border: 0.834059px solid #ffffff;
  transform: rotate(180deg);
}

.progress-bar-title {
  font-family: "Poppins-Bold";
  font-style: normal;
  font-weight: 900;
  font-size: 12.0087px;
  line-height: 15px;

  color: #ffffff;
}

.progress-bar-container-mobile {
  display: none;
}

@media (max-width: 960px) {
  .progress-bar-container {
    display: none;
  }

  .progress-bar-container-mobile {
    /* width: 350px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .progress-bar-icon-title-container-mobile {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .progress-bar-content-icons-mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .progress-bar-icon-container-mobile {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1.03423px solid #ffffff;
    box-sizing: border-box;
  }

  .progress-bar-line-spaced-mobile {
    width: 70px;
    height: 1px;
    margin-top: 5px;
    align-self: center;
    border: 0.834059px solid #ffffff;
    transform: rotate(180deg);
  }

  .progress-bar-title-mobile {
    font-family: "Poppins-Bold";
    font-style: normal;
    font-weight: 900;
    font-size: 10.0087px;
    line-height: 15px;

    color: #ffffff;
  }
}
