.banner-home {
    max-width: 100%;
    height: auto;
    display: flex;

}

.banner-home img {
    width: 100%;
    height: auto;
    display: flex;
}

.alice-carousel__dots {
    /* padding: 0;
    list-style: none;
    text-align: center; */
    margin-top: -3%;
    /* display: flex; */
    position: absolute;
    margin-left: 48%;
}

@media(max-width: 900px) {
    .alice-carousel__dots{
        display: none;
    }
}

.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #fff;
}

.alice-carousel__dots-item:not(.__custom) {
    background-color: transparent;
    border: 1px solid #fff;   
}

/* .alice-carousel__prev-btn {
    background-image: url('../../../assets/images/arrow-home-rigth.svg');
    background-repeat: no-repeat;
    background-size: 35px;
    color: transparent;
    display: flex;
    cursor: pointer;
    padding: 5px;
    margin: 0;
    position: absolute;
    margin-top: -20%;
    margin-left: 2%;
}

.alice-carousel__prev-btn [data-area]::after, .alice-carousel__next-btn {
    color: transparent;
    display: flex;
    background-image: url('../../../assets/images/arrow-home-left.svg');
    background-repeat: no-repeat;
    background-size: 35px;
    position: absolute;
    margin-top: -20%;
    margin-left: 60%;
} */


.alice-carousel__prev-btn [data-area]::after, .alice-carousel__next-btn [data-area]::after {
    content: none;
}