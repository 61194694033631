.pageTitleContainer {
  width: 90%;
  max-width: 1120px;
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/images/title-background-left.png"),
    url("../../assets/images/title-background-right.png");
  background-repeat: no-repeat, no-repeat;
  background-position: center left, center right;
  background-size: 70px;
  margin: 0 auto;
  padding: 30px 85px;
}

.pageTitleContainer h1 {
  font-family: "Poppins-Black";
  font-size: 2rem;
}

@media screen and (max-width: 730px) {
  .pageTitleContainer h1 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .pageTitleContainer {
    background: url("../../assets/images/title-background-right.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 70px;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    padding-right: 85px;
  }

  .pageTitleContainer h1 {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 300px) {
  .pageTitleContainer {
    background-size: 50px;
  }
}
