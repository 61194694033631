.institucionalMain {
  width: 100%;
}

.institucionalMain .options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.institucionalMain .options .optionsContent {
  width: 90%;
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 0;
}

.institucionalMain .options .optionsContent h2 {
  font-family: "Poppins-Bold";
  font-size: 3rem;
  text-align: center;
  margin: 0 0 2rem;
}

.institucionalMain .options .optionsContent .buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.institucionalMain .options .optionsContent .buttons :is(a, button) {
  width: 180px;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #db0d15;
  border-radius: 17px;
  padding: 0 2%;
  text-align: center;
  color: #fff;
  font-family: "Poppins-Bold";
  font-size: 0.9rem;
  text-decoration: none;
  transition: all 0.2s;
  cursor: pointer;
  border: 0;
}

.institucionalMain .options .optionsContent .buttons :is(a, button):hover {
  transform: scale(1.1);
}

.institucionalMain .options .optionsContent .buttons :is(a, button) img {
  width: 25px;
  margin-right: 10px;
}

.institucionalMain .downloadApp {
  width: 100%;
  background: #ffffff;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 3rem 0;
}

.institucionalMain .downloadApp .downloadAppContent {
  width: 90%;
  max-width: 1120px;
  margin: 0 auto;
  display: grid;
  grid-template-areas:
    "cellphone  downloadInfo"
    "cellphone  downloadCards";
}

.institucionalMain .downloadApp .downloadAppContent .cellphoneImage {
  grid-area: cellphone;
  width: 80%;
}

.institucionalMain .downloadApp .downloadAppContent .downloadInfo {
  grid-area: downloadInfo;
  margin-top: auto;
}

.institucionalMain .downloadApp .downloadAppContent .downloadInfo .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.institucionalMain .downloadApp .downloadAppContent .downloadInfo .title h2 {
  font-family: "Poppins-Regular";
  font-weight: 600;
  font-size: 2.5rem;
  color: #f5b807;
  margin-bottom: 10px;
}

.institucionalMain
  .downloadApp
  .downloadAppContent
  .downloadInfo
  .title
  .titleGroup {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-family: "Poppins-Bold";
  color: #f5b807;
  padding: 0;
  margin: 0;
}

.institucionalMain
  .downloadApp
  .downloadAppContent
  .downloadInfo
  .title
  .titleGroup
  h1 {
  font-size: 4rem;
  margin: 0;
}

.institucionalMain
  .downloadApp
  .downloadAppContent
  .downloadInfo
  .title
  .titleGroup 
  h3 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-family: "Poppins-Bold";
  color: #515151;
  padding: 0;
  margin: 0;
}

.institucionalMain
  .downloadApp
  .downloadAppContent
  .downloadInfo
  .title
  /* .titleGroup  */
  .buttonGiga {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5b807;
    border-radius: 5px;
    padding: 0 30px;
    font-family: "Poppins-Black";
    text-align: center;
    color: #000000;
    font-size: 0.9rem;
  }

.institucionalMain
  .downloadApp
  .downloadAppContent
  .downloadInfo
  .title
  .titleGroup
  p {
  color: #515151;
  margin-left: -20px;
}

.institucionalMain
  .downloadApp
  .downloadAppContent
  .downloadInfo
  .title
  .titleGroup
  p
  span {
  color: #f5b807;
}

.institucionalMain
  .downloadApp
  .downloadAppContent
  .downloadInfo
  .downloadButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.institucionalMain
  .downloadApp
  .downloadAppContent
  .downloadInfo
  .downloadButtons
  a {
  width: 48%;
  background-color: #000;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s;
}

.institucionalMain
  .downloadApp
  .downloadAppContent
  .downloadInfo
  .downloadButtons
  a:hover {
  transform: scale(1.1);
}

.institucionalMain
  .downloadApp
  .downloadAppContent
  .downloadInfo
  .downloadButtons
  a
  .text {
  color: #fff;
  margin-left: 10px;
  font-size: 1.2rem;
  font-family: "Poppins-Bold";
}

.institucionalMain
  .downloadApp
  .downloadAppContent
  .downloadInfo
  .downloadButtons
  a
  .text
  span {
  font-size: 0.8rem;
}

.institucionalMain .downloadApp .downloadAppContent .cardsInfo {
  grid-area: downloadCards;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 3rem;
}

.institucionalMain .downloadApp .downloadAppContent .cardsInfo .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  margin: 0 20px 20px;
}

.institucionalMain .downloadApp .downloadAppContent .cardsInfo .card .icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      180.24deg,
      #db0d15 26.66%,
      rgba(245, 130, 32, 0.87) 74.38%
    ),
    #c4c4c4;
  border-radius: 7px;
}

.institucionalMain .downloadApp .downloadAppContent .cardsInfo .card .icon img {
  width: 30px;
}

.institucionalMain
  .downloadApp
  .downloadAppContent
  .cardsInfo
  .card
  .description {
  font-family: "Poppins-Bold";
  font-size: 0.7rem;
  text-align: center;
  color: #515151;
}

.institucionalMain .benefitsContainer {
  width: 100%;
  background: linear-gradient(
    270deg,
    #ba282b 4.1%,
    #e8474d 52.73%,
    #ba282b 97.46%
  );
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.institucionalMain .benefitsContainer .content {
  width: 90%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.institucionalMain .benefitsContainer .content .felixImage {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.institucionalMain .benefitsContainer .content .felixImage img {
  width: 100%;
}

.institucionalMain .benefitsContainer .content .benefitsContent {
  width: 50%;
}

.institucionalMain .benefitsContainer .content .benefitsContent h2 {
  font-family: "Poppins-Regular";
  font-weight: 600;
  font-size: 2rem;
  text-align: left;
  color: #ffffff;
}

.institucionalMain .benefitsContainer .content .benefitsContent h2 span {
  font-family: "Poppins-Black";
  font-size: 3.3rem;
  text-align: center;
  color: #f5b807;
}

.institucionalMain .benefitsContainer .content .benefitsContent .cards {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.institucionalMain .benefitsContainer .content .benefitsContent .cards .card {
  width: 48%;
  max-width: 250px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #a80004;
  border-radius: 8px;
}

.institucionalMain
  .benefitsContainer
  .content
  .benefitsContent
  .cards
  :is(.card:nth-child(1), .card:nth-child(2)) {
  margin-bottom: 30px;
}

.institucionalMain
  .benefitsContainer
  .content
  .benefitsContent
  .cards
  .card
  .icon {
  width: 65px;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #db0d15;
}

.institucionalMain
  .benefitsContainer
  .content
  .benefitsContent
  .cards
  .card
  .icon
  img {
  width: 30px;
}

.institucionalMain
  .benefitsContainer
  .content
  .benefitsContent
  .cards
  .card
  .text {
  width: calc(100% - 65px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.institucionalMain
  .benefitsContainer
  .content
  .benefitsContent
  .cards
  .card
  .text
  p {
  font-family: "Roboto-Regular";
  font-size: 1rem;
  text-align: center;
  color: #ffffff;
}

.institucionalMain
  .benefitsContainer
  .content
  .benefitsContent
  .cards
  .contractNow {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5b807;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0 30px;
  font-family: "Poppins-Black";
  text-align: center;
  color: #000000;
  margin: 4rem auto 0;
  cursor: pointer;
  transition: all 0.2s;
}

.institucionalMain
  .benefitsContainer
  .content
  .benefitsContent
  .cards
  .contractNow:hover {
  transform: scale(1.1);
}

.institucionalMain
  .benefitsContainer
  .content
  .benefitsContent
  .cards
  .contractNow
  img {
  width: 20px;
  margin-left: 10px;
}

@media screen and (max-width: 999px) {
  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .downloadButtons
    a
    .text {
    font-size: 1rem;
  }
}

@media screen and (max-width: 890px) {
  .institucionalMain .options .optionsContent h2 {
    font-size: 2.5rem;
  }

  .institucionalMain .options .optionsContent .buttons :is(a, button) {
    width: 150px;
    height: 48px;
    font-size: 0.8rem;
  }

  .institucionalMain .downloadApp .downloadAppContent .downloadInfo .title h2 {
    font-size: 2.1rem;
  }

  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .title
    .titleGroup
    h1 {
    font-size: 3rem;
  }

  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .downloadButtons {
    flex-direction: column;
    align-items: flex-start;
  }

  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .downloadButtons
    a {
    width: 70%;
  }

  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .downloadButtons
    a:first-child {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 745px) {
  .institucionalMain .downloadApp .downloadAppContent {
    grid-template-areas:
      "cellphone  downloadInfo"
      "downloadCards  downloadCards";
  }

  .institucionalMain .downloadApp .downloadAppContent .cellphoneImage {
    width: 90%;
  }

  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .title
    .titleGroup
    p {
    font-size: 0.8rem;
  }

  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .downloadButtons
    a {
    width: 100%;
  }
}

@media screen and (max-width: 710px) {
  .institucionalMain .options {
    background: none;
  }

  .institucionalMain .options .optionsContent {
    height: auto;
    padding-bottom: 2rem;
  }

  .institucionalMain .options .optionsContent h2 {
    font-size: 2rem;
  }

  .institucionalMain .options .optionsContent .buttons {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .institucionalMain .options .optionsContent .buttons :is(a, button) {
    width: 35%;
    height: 70px;

    min-width: 130px;
    font-size: 0.8rem;
    font-size: 1.2rem;
  }

  .institucionalMain
    .options
    .optionsContent
    .buttons
    :is(a:nth-child(1), a:nth-child(2)) {
    margin-bottom: 2rem;
  }

  .institucionalMain .benefitsContainer .content {
    flex-direction: column;
  }

  .institucionalMain .benefitsContainer .content .benefitsContent {
    width: 100%;
  }

  .institucionalMain .benefitsContainer .content .felixImage {
    width: 60%;
  }

  .institucionalMain .benefitsContainer .content .benefitsContent h2 {
    margin: 40px auto 20px;
    width: fit-content;
  }
}

@media screen and (max-width: 640px) {
  .institucionalMain .options .optionsContent h2 {
    font-size: 1.6rem;
  }

  .institucionalMain .downloadApp .downloadAppContent {
    grid-template-areas:
      "downloadInfo"
      "cellphone"
      "downloadCards";
  }

  .institucionalMain .downloadApp .downloadAppContent .cellphoneImage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .institucionalMain .downloadApp .downloadAppContent .cellphoneImage img {
    width: 80%;
  }

  .institucionalMain .downloadApp .downloadAppContent .downloadInfo .title {
    width: fit-content;
    margin: 0 auto;
  }

  .institucionalMain .downloadApp .downloadAppContent .downloadInfo .title h2 {
    font-size: 3rem;
  }

  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .title
    .titleGroup {
    justify-content: flex-end;
  }

  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .title
    .titleGroup
    h1 {
    font-size: 3.5rem;
  }

  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .title
    .titleGroup
    p {
    font-size: 1.5rem;
  }

  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .downloadButtons
    a {
    width: 60%;
    margin: 0 auto;
  }

  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .downloadButtons {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 520px) {
  .institucionalMain .options .optionsContent .buttons :is(a, button) {
    font-size: 0.8rem;
  }

  .institucionalMain .downloadApp .downloadAppContent .cellphoneImage {
    width: 100%;
  }

  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .downloadButtons
    a
    img {
    width: 20px;
    height: 20px;
  }

  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .downloadButtons
    a
    .text {
    font-size: 0.9rem;
  }

  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .downloadButtons
    a
    .text
    span {
    font-size: 0.5rem;
  }
}

@media screen and (max-width: 500px) {
  .institucionalMain .benefitsContainer .content .benefitsContent h2 {
    font-size: 1.5rem;
  }

  .institucionalMain .benefitsContainer .content .benefitsContent h2 span {
    font-size: 2.8rem;
  }

  .institucionalMain .benefitsContainer .content .benefitsContent .cards {
    flex-direction: column;
    justify-content: center;
  }

  .institucionalMain .benefitsContainer .content .benefitsContent .cards .card {
    width: 70%;
    max-width: unset;
    margin-bottom: 30px;
  }

  .institucionalMain
    .benefitsContainer
    .content
    .benefitsContent
    .cards
    .contractNow {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 440px) {
  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .title
    .titleGroup
    h1 {
    font-size: 2.5rem;
  }

  .institucionalMain
    .downloadApp
    .downloadAppContent
    .downloadInfo
    .title
    .titleGroup
    p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 290px) {
  .institucionalMain .options .optionsContent .buttons button {
    margin-top: 2rem;
  }
}
