.backButton {
  width: 9.3rem;
  height: 2.6rem;
  background-color: #fff;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  font-family: "Poppins-Bold";
  font-weight: 800;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  gap: 10px;
  overflow: hidden;
}

.backButton:hover {
  transform: scale(1.1);
}

.backButton[disabled] {
  filter: brightness(0.9);
  cursor: not-allowed;
  transform: unset;
}
