.submitButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 90px;
}

@media screen and (max-width: 550px) {
  .submitButtonContainer {
    flex-direction: column-reverse;
    gap: 2rem;
  }
}
