.continueButton {
  color: #ffffff !important;
  background-color: #40be39 !important;
  overflow: hidden;
  width: 9.3rem;
  height: 2.6rem;
  background-color: #fff;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  font-family: "Poppins-Bold";
  font-weight: 800;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.continueButton:hover {
  transform: scale(1.1);
}

.continueButton[disabled] {
  filter: brightness(0.9);
  cursor: not-allowed;
  transform: unset;
}
