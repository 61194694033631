.label-info--container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

label.label-info--title {
  background-color: #fff;
  font-family: "Poppins-Bold", sans-serif;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  color: #515151;
  border: none;
  padding: 0px;
  pointer-events: none;
}

span.label-info--text {
  font-family: "Poppins-Bold", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: #515151;
}
