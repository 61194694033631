.button-sign-now {
  width: 142.34px;
  height: 37px;
  background-color: #a80004;
  justify-content: center;
  align-items: center;
  display: flex;
  align-self: center;
  margin-top: 10px;
  color: #fff;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  line-height: 17px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
  border: none;

  text-align: center;

  transition: all 0.3s ease-in-out;
}

.button-sign-now:hover {
  background-color: #93040a;
}
.button-sign-now:disabled {
  cursor: not-allowed;
}

@media (max-width: 1280px) {
  .button-sign-now {
    width: 100px;
    background-color: #a80004;
    justify-content: center;
    align-items: center;
    display: flex;
    align-self: center;
    margin-top: 10px;
    color: #fff;
    font-family: "Poppins-Bold";
    font-size: 15px;
    line-height: 24px;
    font-weight: bold;
    text-transform: capitalize;
    cursor: pointer;

    text-align: center;

    transition: all 0.3s ease-in-out;
  }
}

/* @media (max-width: 960px) {
  .button-sign-now-another {
    background-color: #FFFFFF;
    border-radius: 5px;
    border-color: transparent;
    color: #93040a;
    border: none;
  }

  .button-sign-now-another:hover {
    background-color: rgb(179, 103, 103);
  }
} */
