header {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5fa;
  box-shadow: -10px -10px 20px #ffffff, -10px -10px 20px #ffffff,
    2px 2px 10px #605e75, 2px 2px 2px rgba(170, 170, 204, 0.4);
}

header nav {
  width: 100%;
  height: 100%;
  /* max-width: 1120px; */
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header nav .group {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header nav .group:last-child {
  gap: 20px;
}

header nav .group .logo {
  width: 7rem;
  margin-right: 4rem;
}

header nav .group .dropdown {
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
}

header nav .group .dropdown h2 {
  font-family: "Poppins-Regular";
  font-weight: 300;
  color: #000000;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

header nav .group .dropdown h2 svg {
  margin-left: 10px;
  width: 12%;
}

header nav .group .dropdown .options {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  display: none;
  background-color: #ffffff;
  padding: 0.9rem;
  border-radius: 5px;
  z-index: 1;
}

header nav .group .dropdown:hover {
  top: 95px;
}

header nav .group .dropdown:hover .options {
  display: flex;
}

header nav .group .dropdown .options a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Poppins-Bold";
  font-size: 1rem;
  color: #000000;
  padding: 10px 5px;
  transition: all 0.2s;
  border-radius: 4px;
}

header nav .group .dropdown .options a:hover {
  background-color: rgba(237, 197, 197, 0.5);
  color: #a80004;
}

header nav .group .dropdown .options a img {
  margin-right: 10px;
}

header nav .group .contractFromPhone {
  font-family: "Poppins-Regular";
  color: #000000;
  margin-left: 2rem;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.9rem;
}

header nav .group .contractFromPhone:hover {
  color: #a80004;
  text-decoration: underline;
}

header nav .group .contractFromPhone svg {
  margin-left: 5px;
}

header nav .group .contractNow {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5b807;
  border-radius: 5px;
  padding: 0 30px;
  font-family: "Poppins-Black";
  text-align: center;
  color: #000000;
  font-size: 0.9rem;
}

header nav .group .contractNow img {
  width: 20px;
  margin-left: 5px;
}

header nav .group .clientArea {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #db0d15;
  border: 0;
  border-radius: 5px;
  padding: 0 30px;
  font-family: "Poppins-Black";
  text-align: center;
  color: #ffffff;
  font-size: 0.9rem;
}

header nav .group .clientArea svg {
  margin-left: 10px;
}

header nav .group :is(.contractNow:hover, .clientArea:hover) {
  transform: scale(1.1);
  transition: all 0.2s;
}

@media (max-width: 1328px) {
  header
    nav
    .group
    :is(.dropdown h2, .contractFromPhone, .contractNow, .clientArea) {
    font-size: 0.7rem;
  }
}

@media (max-width: 1303px) {
  header
    nav
    .group
    :is(.dropdown h2, .contractFromPhone, .contractNow, .clientArea) {
    font-size: 0.6rem;
  }
}

@media (max-width: 1234px) {
  header nav .group .contractFromPhone {
    font-size: 0.7rem;
  }

  header nav .group .dropdown h2 {
    font-size: 0.7rem;
  }
}

@media (max-width: 1162px) {
  header nav .group .logo {
    width: 6rem;
    margin-right: 3rem;
  }

  header nav .group .dropdown {
    width: 150px;
  }

  header nav .group .dropdown:hover {
    top: 81px;
  }

  header nav .group .dropdown .options a {
    font-size: 0.8rem;
  }

  header nav .group .dropdown .options a img {
    width: 20px;
  }

  header
    nav
    .group
    :is(.dropdown h2, .contractFromPhone, .contractNow, .clientArea) {
    font-size: 0.6rem;
  }
}

@media (max-width: 1053px) {
  header
    nav
    .group
    :is(.dropdown h2, .contractFromPhone, .contractNow, .clientArea) {
    font-size: 0.5rem;
  }
}

@media screen and (min-width: 746px){
  header nav .group:last-child {
    gap: 6px;
  }

  header nav .group .dropdown {
    width: 120px;
  }

  header nav .group .dropdown .options {
    padding: 0.5rem;
  }

  header nav .group .dropdown .options a img {
    width: 12px;
    margin-right: 7px;
  }

  header
    nav
    .group
    :is(.dropdown h2, .contractFromPhone, .contractNow, .clientArea) {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 745px) {
  header nav .group:last-child {
    gap: 3px;
  }

  header nav .group .dropdown {
    width: 120px;
  }

  header nav .group .dropdown .options {
    padding: 0.6rem;
  }

  header nav .group .dropdown .options a img {
    width: 12px;
    margin-right: 7px;
  }

  header
    nav
    .group
    :is(.dropdown h2, .contractFromPhone, .contractNow, .clientArea) {
    font-size: 0.4rem;
  }
}

@media screen and (max-width: 719px) {
  header
    nav
    .group
    :is(.dropdown h2, .contractFromPhone, .contractNow, .clientArea) {
    font-size: 0.3rem;
  }
}
