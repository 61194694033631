body {
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

@font-face {
  font-family: "Kimberley";
  src: local("Kimberley"),
    url("./assets/fonts/KimberleyBl-Regular.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "DIN Pro";
  src: local("DIN Pro"), url("./assets/fonts/DINPro-Light.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "DIN Pro Bold";
  src: local("DIN Pro Bold"),
    url("./assets/fonts/DINPro-Bold.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"),
    url("./assets/fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Black";
  src: local("Poppins-Black"),
    url("./assets/fonts/Poppins-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Black";
  src: local("Roboto-Black"),
    url("./assets/fonts/Roboto-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Light";
  src: local("Roboto-Light"),
    url("./assets/fonts/Roboto-Light.ttf") format("truetype");
}
