.card-content-steps-body-content{
  width: 70%;
  background: #FFFFFF;
  border-radius: 10px;
  margin-bottom: 15%;
  margin-top: 5%;

}
.card-content-steps-body-content-pj{
  width: 70%;
  background: #FFFFFF;
  border-radius: 10px;
  margin-bottom: 15%;
  margin-top: 5%;

}