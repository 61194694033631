div#myaccount {
  margin-top: 80px;
  margin-bottom: 150px;
}

.myaccount-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.myaccount-profile--image {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.myaccount-sidebar {
  margin-right: 80px;
  min-width: 100px;
}

.myaccount-profile--image img {
  max-width: 50px;
  border-radius: 50%;
}

span.myaccount-profile--name {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #db0d15;
  margin-left: 10px;
  white-space: nowrap;
}

h4.myaccount-content--title {
  font-family: "Poppins-Bold";
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #515151;
  margin: 37px 0px 45px;
  white-space: nowrap;
}

ul.myaccount-menu--list {
  margin: 0px;
  padding: 0px;
}

ul.myaccount-menu--list li {
  list-style: none;
}

ul.myaccount-menu--list li button {
  background: #fff;
  border: none;
  font-family: "Poppins-Bold", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px;
  white-space: nowrap;
}

ul.myaccount-menu--list li.link--active button {
  color: #db0d15;
  font-weight: bold;
}

.myaccount-profile-card--info {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 35px 35px 0px;
}

.myaccount-profile-card--info,
.myaccount-profile--edit {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.myaccount-profile-card--info.myaccount-profile-card--password,
.myaccount-card.edit--profile,
.myaccount-profile--edit,
.myaccount-profile--content {
  width: 100%;
}

.myaccount-profile-card--info.myaccount-profile-card--password {
  margin-left: 23px;
}

button.myaccount-edit-button {
  font-family: "Poppins-Bold";
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  background-color: #db0d15;
  min-width: 11vw;
}

.myaccount-profile-card--info.myaccount-profile-card--password > div {
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .myaccount-profile--edit,
  .myaccount-content {
    flex-direction: column;
  }

  .myaccount-sidebar {
    margin-bottom: 20px;
  }

  .myaccount-profile-card--info.myaccount-profile-card--password {
    margin-left: 0px;
    margin-top: 10px;
  }

  div#myaccount {
    margin-top: 10px;
  }
}
