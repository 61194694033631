.footer {
  width: 100%;
  /* margin-top: 30px; */
  background-color: #f3f3f3;
  padding-top: 30px;
}

.footer .footer-top {
  display: flex;
  /* margin: 0 auto; */
  justify-content: space-evenly;
  width: 100%;
}

.footer .footer-bottom {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.footer .footer-bottom .footer-bottom-row {
  display: flex;
  height: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
}

.col-footer .social-networks {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.col-footer .social-networks h3 {
  margin-right: 22px;
  font-family: "Poppins-Bold";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;

  color: #26231e;
}

.col-footer .social-networks a {
  margin-right: 9px;
}
f .footer .footer-bottom .footer-bottom-row .text-footer span {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  text-align: center;

  color: #6f6f6f;
}

.footer .footer-bottom .footer-bottom-row .powered-by {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer .footer-bottom .footer-bottom-row .powered-by h3 {
  font-family: "Poppins-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-right: 10px;
  color: #6f6f6f;
}

.footer .footer-top .col-footer div {
  display: flex;
  flex-direction: column;
}

.footer .footer-top .col-footer div a {
  font-family: "Roboto-Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #26231e;
  text-decoration: none;
  margin-bottom: 20px;
}

.footer .footer-top .col-footer .seguranca {
  max-width: 49px;
}

.btn-footer {
  display: none;
}

.footer .footer-top .col-footer div .title-payments-methods {
  display: none;
}

.col-footer h3 {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: #26231e;
}

.col-contain-desk a {
  font-family: "Roboto-Regular", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.social-networks-icons {
  display: flex;
}

.text-footer {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #6f6f6f;
}

.imgFooter {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 960px) {
  .footer {
    max-width: 100%;
    background-image: none;
  }
  .footer .footer-top,
  .footer .footer-bottom,
  .footer .footer-bottom .footer-bottom-row {
    flex-direction: column;
  }
  .footer .footer-top {
    align-items: center;
    padding: 0;
  }
  .footer .footer-top .col-footer {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .footer .footer-top .col-footer div {
    width: 100%;
  }
  .footer .footer-top .col-footer div h3 {
    display: none;
  }
  .footer .footer-top .col-footer div button {
    height: unset !important;
    outline: none !important;
    border: none !important;
    padding: unset !important;
    background: none !important;
    display: flex;
    width: 100%;
    margin-top: 0px;
  }
  .footer .footer-top .col-footer div button h3 {
    width: 100%;
    background: #f3f3f3;
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #4f4f4f;
    padding: 30px 22px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer .footer-top .col-footer div button h3::after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' viewBox='0 0 16 12' fill='none'%3E%3Cpath d='M1.70605 1.90723L8.44678 9.35426L14.7061 1.90723' stroke='%23DB0D15' stroke-width='3'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: inline-flex;
    height: 12px;
    width: 16px;
  }
  .text-footer {
    display: none;
  }
  .col-contain {
    transition: all 0.5s ease-out;
    text-align: center;
    max-width: 100%;
    justify-content: center;
    box-sizing: border-box;
    margin: 10px 0;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .footer .footer-top .col-footer .seguranca {
    align-self: center;
  }
  .btn-footer {
    margin-bottom: 7px;
  }
  .footer .footer-top .col-footer div .title-payments-methods {
    display: flex;
    font-family: "Poppins-Bold";
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #4f4f4f;
    padding-top: 53px;
  }
  .col-footer:last-child {
    background: #f2f2f2;
  }
  .img-payments-methods {
    /* max-width: 311px;
        height: auto; */
    margin: 0 auto;
  }
  .footer .footer-bottom .footer-bottom-row .social-networks {
    display: flex;
    flex-direction: column;
  }
  .col-contain-desk {
    display: none !important;
  }
  .footer-bottom {
    background: #f2f2f2;
  }

  .img-payments-methods {
    padding: 0px 20px;
    box-sizing: border-box;
  }

  .footerLogoMob {
    display: none;
  }
}
