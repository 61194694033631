.headerContainer {
  width: 100%;
  height: 60px;
  background: #f5f5fa;
  box-shadow: -10px -10px 20px #ffffff, -10px -10px 20px #ffffff,
    2px 2px 10px #605e75, 2px 2px 2px rgba(170, 170, 204, 0.4);
}

.headerContainer nav {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerContainer nav .logo {
  width: 4rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.headerContainer nav .logo img {
  width: 100%;
}

.headerContainer nav .buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.headerContainer nav .buttons .contractNow {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5b807;
  border-radius: 5px;
  padding: 0 30px;
  font-family: "Poppins-Black";
  text-align: center;
  color: #000000;
  font-size: 0.9rem;
}

.headerContainer nav .buttons .contractNow:hover {
  transform: scale(1.1);
  transition: all 0.2s;
}

.headerContainer nav .buttons .contractNow img {
  width: 20px;
  margin-left: 5px;
}

.headerContainer nav .buttons svg {
  cursor: pointer;
}

.menuOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.menuOverlay .menu {
  width: 80%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-left: auto;
  border-bottom-left-radius: 3px;
}

.menuOverlay .menu header {
  width: 100%;
  height: 60px;
  background: unset;
  box-shadow: unset;
  justify-content: space-between;
  padding: 1.9rem 1.2rem;
  border-bottom: 1px solid #e6e6e6;
}

.menuOverlay .menu header img {
  width: 3.5rem;
  opacity: 0.5;
}

.menuOverlay .menu header svg {
  cursor: pointer;
}

.menuOverlay .menu .options {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.9rem 1.2rem;
}

.menuOverlay .menu .options a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Poppins-Bold";
  color: #000000;
  padding: 18px 0;
  border-bottom: 1px solid #e6e6e6;
}

.menuOverlay .menu .options a svg {
  margin-left: 10px;
}

.menuOverlay .menu .options a img {
  width: 1.3rem;
  margin-right: 10px;
}

.menuOverlay .menu .options .clientArea {
  height: 40px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #db0d15;
  border: 0;
  border-radius: 5px;
  padding: 0 30px;
  font-family: "Poppins-Black";
  text-align: center;
  color: #ffffff;
  font-size: 0.8rem;
  margin-top: 30px;
  transition: all 0.2s;
}

.menuOverlay .menu .options .clientArea:hover {
  transform: scale(1.1);
}

.menuOverlay .menu .options .clientArea svg {
  margin-left: 10px;
}

@media screen and (max-width: 350px) {
  .headerContainer nav .buttons .contractNow {
    padding: 0 15px;

    font-size: 0.7rem;
  }
}
