header {
  width: 100%;
  height: 100px;
  background: #f5f5fa;
  box-shadow: -2.60417px -2.60417px 5.20833px #ffffff,
    0.520833px 0.520833px 0.520833px rgba(170, 170, 204, 0.4),
    0.520833px 0.520833px 2.60417px #605e75,
    -2.60417px -2.60417px 5.20833px #ffffff;
  margin: 0;
  padding: 0;
}

header .content {
  width: 90%;
  height: 100%;
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

header .content a {
  display: flex;
  width: 7rem;
  height: 100%;
}

header .content a img {
  width: 100%;
}

@media screen and (max-width: 710px) {
  header {
    height: 40px;
  }

  header .content a {
    display: flex;
    width: 3rem;
    height: 100%;
  }
}
