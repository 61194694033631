.customModal {
  border-radius: 30px;
  width: 90%;
  text-align: center !important;
}

.customModal > h3 {
  font-family: "Poppins-Bold";
}

.customModal > p {
  font-family: "Poppins-Regular";
}
